import { Policy, ECareCard } from '../../types/policy';
import { Dependant, PulseUserConfig } from '../../types/claim';
import {
  CLEAR_POLICY,
  SELECT_POLICY,
  FETCH_MY_POLICY_FAILED,
  FETCH_MY_POLICY_START,
  FETCH_MY_POLICY_SUCCESS,
  FETCH_ECARE_CARD_START,
  FETCH_ECARE_CARD_SUCCESS,
  FETCH_ECARE_CARD_FAILED,
  FETCH_POLICY_DETAIL_SUCCESS,
  FETCH_POLICY_DETAIL_START,
  FETCH_POLICY_DETAIL_FAILED,
  MyPolicyAction,
  FETCH_DEPENDANT_START,
  FETCH_DEPENDANT_SUCCESS,
  FETCH_DEPENDANT_FAILED,
  GET_PULSE_USER_CONFIG_START,
  GET_PULSE_USER_CONFIG_SUCCESS,
  GET_PULSE_USER_CONFIG_FAILED,
  FETCH_POLICY_DEPENDANT_START,
  FETCH_POLICY_DEPENDANT_SUCCESS,
  FETCH_POLICY_DEPENDANT_FAILED
} from './actions';

export type { MyPolicyAction };

type MyPolicyState = {
  policy?: Policy;
  policyDetail?: any | undefined;
  policies?: Policy[];
  eCareCard?: ECareCard[];
  fetchError?: string;
  loading: boolean;
  eCareCardLoading: boolean;
  dependants: Dependant[];
  pulseUserConfig?: PulseUserConfig;
  calledEcareCard: boolean;
  policyDependant: any | undefined;
  fetchErrorDependant?: string;
  loadingDependant: boolean;
};

export const initialState: MyPolicyState = {
  policy: undefined,
  policyDetail: undefined,
  policies: [],
  eCareCard: [],
  fetchError: undefined,
  loading: false,
  eCareCardLoading: false,
  dependants: [],
  pulseUserConfig: undefined,
  calledEcareCard: false,
  policyDependant: undefined,
  fetchErrorDependant: undefined,
  loadingDependant: false
};

const MyPolicyReducer = (state: MyPolicyState = initialState, action: MyPolicyAction): MyPolicyState => {
  switch (action.type) {
    case FETCH_MY_POLICY_START:
      return {
        ...state,
        loading: true,
        fetchError: undefined
      };

    case FETCH_MY_POLICY_SUCCESS:
      return {
        ...state,
        policies: action.payload,
        policy: action.payload[0],
        loading: false
      };

    case FETCH_MY_POLICY_FAILED:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };
    case FETCH_POLICY_DETAIL_START:
      return {
        ...state,
        loading: true,
        fetchError: undefined
      };

    case FETCH_POLICY_DETAIL_SUCCESS:
      return {
        ...state,
        policyDetail: action.payload,
        loading: false
      };

    case FETCH_POLICY_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };
    case FETCH_POLICY_DEPENDANT_START:
      return {
        ...state,
        loadingDependant: true,
        fetchErrorDependant: undefined
      };

    case FETCH_POLICY_DEPENDANT_SUCCESS:
      return {
        ...state,
        policyDependant: action.payload,
        loadingDependant: false
      };

    case FETCH_POLICY_DEPENDANT_FAILED:
      return {
        ...state,
        loadingDependant: false,
        fetchErrorDependant: action.payload
      };

    case FETCH_ECARE_CARD_START:
      return {
        ...state,
        eCareCardLoading: true,
        fetchError: undefined
      };

    case FETCH_ECARE_CARD_SUCCESS:
      return {
        ...state,
        calledEcareCard: true,
        eCareCard: action.payload,
        eCareCardLoading: false
      };

    case FETCH_ECARE_CARD_FAILED:
      return {
        ...state,
        calledEcareCard: true,
        eCareCardLoading: false,
        fetchError: action.payload
      };

    case CLEAR_POLICY:
      return initialState;
    case SELECT_POLICY:
      return {
        ...state,
        policy: state.policies?.find((item) => item.certificateNo === action.payload),
        loading: false
      };
    case FETCH_DEPENDANT_START:
      return {
        ...state,
        loading: true
      };
    case FETCH_DEPENDANT_SUCCESS:
      return {
        ...state,
        dependants: action.payload,
        loading: false
      };

    case FETCH_DEPENDANT_FAILED:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };
    case GET_PULSE_USER_CONFIG_START:
      return {
        ...state,
        loading: true
      };
    case GET_PULSE_USER_CONFIG_SUCCESS:
      return {
        ...state,
        pulseUserConfig: action.payload,
        loading: false
      };
    case GET_PULSE_USER_CONFIG_FAILED:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };
    default:
      return state;
  }
};

export default MyPolicyReducer;
