import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';

import { Locale } from '../types/sdk';

import locale from './locale.json';

interface I18nOptions {
  initialLocale: Locale;
  projectId: string;
  apiKey: string;
}

export const initI18n = ({ initialLocale, apiKey, projectId }: I18nOptions): Promise<TFunction> => {
  const locizeOptions = {
    apiKey,
    projectId,
    referenceLng: 'en'
  };

  const initOptions = {
    lng: initialLocale,
    fallbackLng: 'en',
    ns: [
      'app_ebsme_pulse',
      'global_pluk',
      'global_plt',
      'global_pacs',
      'global_pmli',
      'module_product_catalogue_pluk',
      'module_product_catalogue_thailand',
      'module_product_catalogue_pamb'
    ],
    defaultNS: 'app_ebsme_pulse',
    debug: false,
    saveMissing: false,
    returnObjects: true,
    resources: {
      // Add a default of English in case there are issues loading the
      // translations from the backend
      // en: {
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   app_ebsme_pulse: locale.en
      // }
    },
    partialBundledLanguages: true, // Mix locally + remotely loaded translations
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    editor: {
      ...locizeOptions,
      onEditorSaved: async (
        lng: string | string[] | undefined | null,
        ns: string | string[] | undefined
      ): Promise<void> => {
        await i18n.reloadResources(lng || undefined, ns);
      }
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      useSuspense: false
    }
  };
  return i18n.use(Backend).use(LastUsed).use(initReactI18next).init(initOptions);
};

export default i18n;
